import React, { useState } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const Header = () => {
  // State to toggle mobile menu visibility
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <header className="bg-black text-white w-full">
      <nav className="flex justify-between items-center px-6 lg:px-16 py-4">
        {/* Logo */}
        <div className="text-2xl font-bold tracking-wide">
          <Link to="/" className="text-purple-500 hover:text-pink-500">
            Cyber <span className="text-pink-500">Coderz</span>
          </Link>
        </div>

        {/* Menu for Larger Screens */}
        <ul className="hidden md:flex space-x-8 text-sm font-medium">
          <li>
            <a href="#about" className="hover:text-gray-400">
              About Us
            </a>
          </li>
          <li>
            <a href="#services" className="hover:text-gray-400">
              Our Services
            </a>
          </li>
          <li>
            <a href="#creators" className="hover:text-gray-400">
              Cyber Creators
            </a>
          </li>
          <li>
            <a href="#projects" className="hover:text-gray-400">
              Featured Projects
            </a>
          </li>
        </ul>

        {/* Contact Button */}
        <Link
          to="/contact"
          className="hidden md:block px-6 py-2 bg-white text-black rounded-full text-sm font-medium hover:bg-gray-200"
        >
          Contact Us
        </Link>

        {/* Mobile Menu Icon */}
        <div className="md:hidden">
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="text-white focus:outline-none"
          >
            {isMobileMenuOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            )}
          </button>
        </div>
      </nav>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden bg-black text-white px-6 py-4 space-y-4 text-sm font-medium">
          <a href="#about" className="block hover:text-gray-400">
            About Us
          </a>
          <a href="#services" className="block hover:text-gray-400">
            Our Services
          </a>
          <a href="#creators" className="block hover:text-gray-400">
            Cyber Creators
          </a>
          <a href="#projects" className="block hover:text-gray-400">
            Featured Projects
          </a>
          <Link
            to="/contact"
            className="block px-4 py-2 bg-white text-black rounded-full text-sm font-medium hover:bg-gray-200"
          >
            Contact Us
          </Link>
        </div>
      )}
    </header>
  );
};

export default Header;
