import React from 'react';
import image1 from "./resources/images/image1.png";
import imageone from "./resources/images/01.svg";

import imagetwo from "./resources/images/image2.png";
import image2 from "./resources/images/02.svg";

import imagethre from "./resources/images/image3.png";
import image3 from "./resources/images/03.svg";

import imagefour from "./resources/images/image4.png";
import image4 from "./resources/images/04.svg";
import ".././App.css"

const Home = () => {
  return (
    <div className='w-full'>
      <section className="py-16 px-6 md:px-16 bg-white w-full">
        <div className="flex flex-col md:flex-row justify-between items-center gap-8">
          {/* Left Content */}
          <div className="flex-1 relative max-md:w-[100%]">
            <img
              src={imageone}
              alt="Why We Rock Heading"
              className="mb-6 "
              width={300}
              height={50}
            />
            <div className='absolute top-10'>

           
            <h1 className="text-2xl md:text-3xl font-bold text-black mb-4">
  Building Tomorrow’s Digital Experiences Today
</h1>
<p className="text-[14px] max-md:text-[13px]">
  At CyberCoderz Technologies, we craft transformational digital experiences that foster loyalty, boost sales, and deliver actionable insights. With expertise in web app development, UI/UX design, and mobile solutions, we partner with the world's best brands to redefine how businesses connect with their audiences.
</p>

            </div>
          </div>

          {/* Right Image */}
          <div className="flex-1 flex justify-center md:justify-end">
            <img
              src={image1}
              alt="Why We Rock Heading"
              className="rounded-lg shadow-lg"
              width={450}
              height={405}
            />
          </div>
        </div>
      </section>

      <section className="py-16 px-6 md:px-16 bg-white">
        <div className="flex flex-col md:flex-row justify-between items-center gap-8">
          {/* Left Content */}
        

          {/* Right Image */}
          <div className="flex-1 flex justify-start md:justify-start">
            <img
              src={imagetwo}
              alt="Why We Rock Heading"
              className="rounded-lg shadow-lg"
              width={450}
              height={405}
            />
          </div>

          <div className="flex-1 relative max-md:w-[100%]">
            <img
              src={image2}
              alt="Why We Rock Heading"
              className="mb-6"
              width={300}
              height={50}
            />
            <div className='absolute top-10'>

          
<h1 className="text-2xl md:text-3xl font-bold text-grey-900 mb-4">
  Your Success is Our Innovation
</h1>
<p className="text-grey-600 text-[14px] max-md:text-[13px]">
  CyberCoderz Technologies isn’t just another IT consulting firm; we’re your partners in innovation. By blending consulting, design, and development, we create cutting-edge digital products, from websites to mobile apps, that empower brands to excel in a competitive landscape.
</p>

            </div>
          </div>
        </div>
      </section>

      <section className="py-16 px-6 md:px-16 bg-black">
        <div className="flex flex-col md:flex-row justify-between items-center gap-8">
          {/* Left Content */}
        

          {/* Right Image */}
         

          <div className="flex-1 relative max-md:w-[100%]">
            <img
              src={image3}
              alt="Why We Rock Heading"
              className="mb-6"
              width={300}
              height={50}
            />
            <div className='absolute top-10'>

           
            <h1 className="text-2xl md:text-3xl font-bold text-white mb-4">
  Transforming Ideas Into Digital Reality
</h1>
<p className="text-white text-[14px] max-md:text-[13px]">
  CyberCoderz Technologies is a tech-driven, creativity-powered agency dedicated to reshaping digital landscapes. Specializing in web applications, mobile solutions, and custom visualizations, we deliver experiences that drive growth, enhance engagement, and inspire action.
</p>

            </div>
          </div>

          <div className="flex-1 flex justify-center md:justify-end">
            <img
              src={imagethre}
              alt="Why We Rock Heading"
              className="rounded-lg shadow-lg"
              width={450}
              height={405}
            />
          </div>

        </div>
      </section>

      <section className="py-16 px-6 md:px-16 bg-black mt-[100px]">
        <div className="flex flex-col md:flex-row justify-between items-center gap-8">
          {/* Left Content */}
        

          {/* Right Image */}

          <div className="flex-1 flex justify-center md:justify-start">
            <img
              src={imagefour}
              alt="Why We Rock Heading"
              className="rounded-lg shadow-lg"
              width={450}
              height={405}
            />
          </div>
         

          <div className="flex-1 relative max-md:w-[100%]">
            <img
              src={image4}
              alt="Why We Rock Heading"
              className="mb-6"
              width={300}
              height={50}
            />
            <div className='absolute top-10'>

          
            <h1 className="text-2xl md:text-3xl font-bold text-white mb-4">
  Leaders in Digital Innovation
</h1>
<p className="text-white text-[14px] max-md:text-[13px]">
  At CyberCoderz Technologies, we specialize in web app development, UI/UX design, and Power BI visualizations. Headquartered in Gurugram, Haryana, we’re a small but mighty team that partners with top-tier brands to create impactful digital products that connect, inspire, and deliver results.
</p>


            </div>
          </div>

         

        </div>
      </section>
    </div>
  );
};

export default Home;


// import { useEffect } from "react";
// import { gsap } from "gsap";


// import React from 'react';
// import image1 from "./resources/images/image1.png";
// import imageone from "./resources/images/01.svg";

// import imagetwo from "./resources/images/image2.png";
// import image2 from "./resources/images/02.svg";

// import imagethre from "./resources/images/image3.png";
// import image3 from "./resources/images/03.svg";

// import imagefour from "./resources/images/image4.png";
// import image4 from "./resources/images/04.svg";
// import ".././App.css"

// const Home = () => {
//   useEffect(() => {
//     gsap.from(".fade-in", { opacity: 0, duration: 1, stagger: 0.3 });
//     gsap.from(".slide-up", { y: 50, opacity: 0, duration: 1, stagger: 0.3 });
//   }, []);

//   return (
//     <div className="w-full">
//       <section className="py-16 px-6 md:px-16 bg-white w-full">
//         <div className="flex flex-col md:flex-row justify-between items-center gap-8">
//           {/* Left Content */}
//           <div className="flex-1 relative max-md:w-[100%] slide-up">
//             <img
//               src={imageone}
//               alt="Why We Rock Heading"
//               className="mb-6"
//               width={300}
//               height={50}
//             />
//             <div className="absolute top-10 fade-in">
//               <h1 className="text-2xl md:text-3xl font-bold text-black mb-4">
//                 Building Tomorrow’s Digital Experiences Today
//               </h1>
//               <p className="text-[14px] max-md:text-[13px] fade-in">
//                 At CyberCoderz Technologies, we craft transformational digital
//                 experiences that foster loyalty, boost sales, and deliver actionable
//                 insights. With expertise in web app development, UI/UX design, and
//                 mobile solutions, we partner with the world's best brands to redefine
//                 how businesses connect with their audiences.
//               </p>
//             </div>
//           </div>

//           {/* Right Image */}
//           <div className="flex-1 flex justify-center md:justify-end">
//             <img
//               src={image1}
//               alt="Why We Rock Heading"
//               className="rounded-lg shadow-lg"
//               width={450}
//               height={405}
//             />
//           </div>
//         </div>
//       </section>

//       <section className="py-16 px-6 md:px-16 bg-white">
//         <div className="flex flex-col md:flex-row justify-between items-center gap-8">
//           {/* Left Content */}
//           {/* Right Image */}
//           <div className="flex-1 flex justify-start md:justify-start">
//             <img
//               src={imagetwo}
//               alt="Why We Rock Heading"
//               className="rounded-lg shadow-lg"
//               width={450}
//               height={405}
//             />
//           </div>

//           <div className="flex-1 relative max-md:w-[100%] slide-up">
//             <img
//               src={image2}
//               alt="Why We Rock Heading"
//               className="mb-6"
//               width={300}
//               height={50}
//             />
//             <div className="absolute top-10 fade-in">
//               <h1 className="text-2xl md:text-3xl font-bold text-grey-900 mb-4">
//                 Your Success is Our Innovation
//               </h1>
//               <p className="text-grey-600 text-[14px] max-md:text-[13px] fade-in">
//                 CyberCoderz Technologies isn’t just another IT consulting firm; we’re
//                 your partners in innovation. By blending consulting, design, and
//                 development, we create cutting-edge digital products, from websites to
//                 mobile apps, that empower brands to excel in a competitive landscape.
//               </p>
//             </div>
//           </div>
//         </div>
//       </section>

//       <section className="py-16 px-6 md:px-16 bg-black">
//         <div className="flex flex-col md:flex-row justify-between items-center gap-8">
//           {/* Left Content */}
//           {/* Right Image */}
//           <div className="flex-1 relative max-md:w-[100%] slide-up">
//             <img
//               src={image3}
//               alt="Why We Rock Heading"
//               className="mb-6"
//               width={300}
//               height={50}
//             />
//             <div className="absolute top-10 fade-in">
//               <h1 className="text-2xl md:text-3xl font-bold text-white mb-4">
//                 Transforming Ideas Into Digital Reality
//               </h1>
//               <p className="text-white text-[14px] max-md:text-[13px] fade-in">
//                 CyberCoderz Technologies is a tech-driven, creativity-powered agency
//                 dedicated to reshaping digital landscapes. Specializing in web
//                 applications, mobile solutions, and custom visualizations, we deliver
//                 experiences that drive growth, enhance engagement, and inspire action.
//               </p>
//             </div>
//           </div>

//           <div className="flex-1 flex justify-center md:justify-end">
//             <img
//               src={imagethre}
//               alt="Why We Rock Heading"
//               className="rounded-lg shadow-lg"
//               width={450}
//               height={405}
//             />
//           </div>
//         </div>
//       </section>

//       <section className="py-16 px-6 md:px-16 bg-black mt-[100px]">
//         <div className="flex flex-col md:flex-row justify-between items-center gap-8">
//           {/* Left Content */}
//           {/* Right Image */}
//           <div className="flex-1 flex justify-center md:justify-start">
//             <img
//               src={imagefour}
//               alt="Why We Rock Heading"
//               className="rounded-lg shadow-lg"
//               width={450}
//               height={405}
//             />
//           </div>

//           <div className="flex-1 relative max-md:w-[100%] slide-up">
//             <img
//               src={image4}
//               alt="Why We Rock Heading"
//               className="mb-6"
//               width={300}
//               height={50}
//             />
//             <div className="absolute top-10 fade-in">
//               <h1 className="text-2xl md:text-3xl font-bold text-white mb-4">
//                 Leaders in Digital Innovation
//               </h1>
//               <p className="text-white text-[14px] max-md:text-[13px] fade-in">
//                 At CyberCoderz Technologies, we specialize in web app development,
//                 UI/UX design, and Power BI visualizations. Headquartered in Gurugram,
//                 Haryana, we’re a small but mighty team that partners with top-tier
//                 brands to create impactful digital products that connect, inspire, and
//                 deliver results.
//               </p>
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Home;
