import React from 'react';

const Footer = () => {
  return (
    // <footer className="bg-gray-100 py-8 px-4 lg:px-16 w-full">
    //   <div className="container mx-auto flex  flex-wrap justify-center items-center">

    //     <div className="w-[25%]">
    //       <a href="index.html">
    //         <div className="text-2xl font-bold tracking-wide">
    //           <span className="text-purple-500">Cyber </span>
    //           <span className="text-pink-500">Coderz</span>
    //         </div>
    //       </a>
    //       <span className="text-gray-600 text-sm block">
    //         © Cyber Coderz 2025 | All rights reserved
    //       </span>
    //     </div>


    //     <div className="w-[25%]">
    //       <label className="block text-gray-800 font-medium mb-1">Work with us</label>
    //       <a
    //         className="text-blue-600 hover:text-blue-800 flex justify-center lg:justify-start items-center"
    //         href="mailto:hr@cybercoderztechnologies.com"
    //       >
    //         hr@cybercoderztechnologies.com
    //         <svg
    //           width="16"
    //           height="17"
    //           viewBox="0 0 16 17"
    //           fill="none"
    //           xmlns="http://www.w3.org/2000/svg"
    //           className="ml-2"
    //         >
    //           <path
    //             d="M12.7261 10.7058L12.4777 3.99955L5.77144 3.75117"
    //             stroke="currentColor"
    //             strokeWidth="1.40453"
    //           />
    //           <line
    //             x1="12.6914"
    //             y1="4.21362"
    //             x2="3.69128"
    //             y2="13.2137"
    //             stroke="currentColor"
    //             strokeWidth="1.40453"
    //           />
    //         </svg>
    //       </a>
    //     </div>


    //     <div className="w-[25%]">
    //       <label className="block text-gray-800 font-medium mb-1">Sales</label>
    //       <a
    //         className="text-blue-600 hover:text-blue-800 flex justify-center lg:justify-start items-center"
    //         href="mailto:hr@cybercoderztechnologies.com"
    //       >
    //         hr@cybercoderztechnologies.com
    //         <svg
    //           width="16"
    //           height="17"
    //           viewBox="0 0 16 17"
    //           fill="none"
    //           xmlns="http://www.w3.org/2000/svg"
    //           className="ml-2"
    //         >
    //           <path
    //             d="M12.7261 10.7058L12.4777 3.99955L5.77144 3.75117"
    //             stroke="currentColor"
    //             strokeWidth="1.40453"
    //           />
    //           <line
    //             x1="12.6914"
    //             y1="4.21362"
    //             x2="3.69128"
    //             y2="13.2137"
    //             stroke="currentColor"
    //             strokeWidth="1.40453"
    //           />
    //         </svg>
    //       </a>
    //     </div>


    //     <div className="w-[25%]">
    //       <label className="block text-gray-800 font-medium mb-1">
    //         Maa Ganga Enclave <br />
    //         Garhi Harsaru <br />
    //       </label>
    //     </div>
    //   </div>
    // </footer>

    
    
    <footer className="bg-gray-100 py-8 px-4 lg:px-16 w-full">
    <div className="container mx-auto flex flex-wrap justify-between items-start gap-8">
      {/* Logo and Copyright Section */}
      <div className="w-full md:w-1/2 lg:w-[25%] text-center md:text-left">
        <a href="index.html">
          <div className="text-2xl font-bold tracking-wide">
            <span className="text-purple-500">Cyber </span>
            <span className="text-pink-500">Coderz</span>
          </div>
        </a>
        <span className="text-gray-600 text-sm block mt-2">
          © Cyber Coderz 2025 | All rights reserved
        </span>
      </div>
  
      {/* Work With Us Section */}
      <div className="w-full md:w-1/2 lg:w-[25%] text-center md:text-left">
        <label className="block text-gray-800 font-medium mb-2">Work with us</label>
        <a
          className="text-blue-600 hover:text-blue-800 flex justify-center md:justify-start items-center"
          href="mailto:hr@cybercoderztechnologies.com"
        >
          hr@cybercoderztechnologies.com
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ml-2"
          >
            <path
              d="M12.7261 10.7058L12.4777 3.99955L5.77144 3.75117"
              stroke="currentColor"
              strokeWidth="1.40453"
            />
            <line
              x1="12.6914"
              y1="4.21362"
              x2="3.69128"
              y2="13.2137"
              stroke="currentColor"
              strokeWidth="1.40453"
            />
          </svg>
        </a>
      </div>
  
      {/* Sales Section */}
      <div className="w-full md:w-1/2 lg:w-[25%] text-center md:text-left">
        <label className="block text-gray-800 font-medium mb-2">Sales</label>
        <a
          className="text-blue-600 hover:text-blue-800 flex justify-center md:justify-start items-center"
          href="mailto:hr@cybercoderztechnologies.com"
        >
          hr@cybercoderztechnologies.com
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ml-2"
          >
            <path
              d="M12.7261 10.7058L12.4777 3.99955L5.77144 3.75117"
              stroke="currentColor"
              strokeWidth="1.40453"
            />
            <line
              x1="12.6914"
              y1="4.21362"
              x2="3.69128"
              y2="13.2137"
              stroke="currentColor"
              strokeWidth="1.40453"
            />
          </svg>
        </a>
      </div>
  
      {/* Address Section */}
      {/* <div className="w-full md:w-1/2 lg:w-[25%] text-center md:text-left">
        <label className="block text-gray-800 font-medium mb-2">
          Maa Ganga Enclave <br />
          Garhi Harsaru <br />
        </label>
      </div> */}
    </div>
  </footer>
   

  );
};

export default Footer;
