import React from 'react';

const HeroSection = () => {
  return (
    <div className="bg-black text-white w-full">
      {/* Hero Content */}
      <div className="text-center px-6 lg:px-32 py-12">
       
 

<h1
  style={{
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "8vw", // Adjusts dynamically based on viewport width
    lineHeight: "1", // Keeps a consistent line height
    textTransform: "capitalize",
    background: "linear-gradient(91.31deg, #f24a74 1.57%, #5469ff 99.91%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
    position: "relative",
    zIndex: 1,
    margin: "0", // Removes default margin for consistent spacing
    textAlign: "center", // Center-aligns text
  }}
>
  {/* A Full Lifecycle <br /> Media Network */}

  A Full Lifecycle IT <br />  Solutions Network
</h1>


<p
  className="mt-4 text-[white] text-[16px]  max-lg:text-[14px] max-sm:text-[13px] text-center "
  style={{
    lineHeight: "1.5",
    maxWidth: "90%",
   marginTop:"50px",
  }}
>
  We are transforming the way businesses approach digital solutions. Through cutting-edge web app development, intuitive UI/UX design, and tailored Power BI custom visuals, CyberCoderz Technologies empowers brands to elevate user experiences, unlock actionable insights, and drive meaningful results.
</p>



        {/* Buttons */}
        <div className="mt-8 space-x-4">
          <button className="px-6 py-3 text-sm md:text-base bg-pink-500 text-white rounded-full hover:bg-pink-600">
            I Am A Brand
          </button>
          <button className="px-6 py-3 text-sm md:text-base bg-transparent text-white border border-white rounded-full hover:bg-white hover:text-black">
            I Am A Creator
          </button>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
