import './App.css';
import Contactus from './components/Contactus';
import Footer from "./components/Footer";
import Header from './components/Header';
import HeroSection from './components/Herosection';
import Home from './components/Home';
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
    <div className='flex flex-col justify-between items-center w-full'>

    <Header />
      <Routes>
      
        <Route path="/" element={
          <>
            <HeroSection />
            <Home />
          </>
        } />
        <Route path="/contact" element={<Contactus />} />
      </Routes>
      <Footer />

    </div>
     
    </>
  );
}

export default App;
