import React, { useState } from "react";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    mobile: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const validate = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Name is required.";
    if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Valid email is required.";
    if (!formData.mobile.trim() || !/^\+?[0-9\s-]+$/.test(formData.mobile))
      newErrors.mobile = "Enter a valid phone number.";
    if (!formData.subject.trim()) newErrors.subject = "Subject is required.";
    if (!formData.message.trim()) newErrors.message = "Message cannot be empty.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear error as user types
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    setIsSubmitting(true);
    setIsSuccess(false);

    try {
      const response = await fetch(
        "https://cybercoderztechnologies.com/contact.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        setIsSuccess(true);
        setFormData({
          name: "",
          email: "",
          subject: "",
          mobile: "",
          message: "",
        });

        // Hide success message automatically after 3 seconds
        setTimeout(() => {
          setIsSuccess(false);
        }, 3000);
      } else {
        const responseData = await response.json();
        alert(responseData.message || "Failed to send message.");
      }
    } catch (error) {
      alert("An error occurred. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex w-full pt-10 pb-10 justify-center items-center min-h-screen bg-gradient-to-br from-purple-500 to-pink-600 px-4">
      <form
        onSubmit={handleSubmit}
        className="bg-white shadow-xl rounded-lg p-8 md:w-1/2 w-full"
        aria-live="polite"
      >
        <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">
          Contact Us
        </h2>

        {isSuccess && (
          <div className="mb-4 text-green-600 bg-green-100 p-4 rounded-lg text-center">
            🎉 Thank you! Your message has been sent!
          </div>
        )}

        {!isSuccess && (
          <div className="space-y-6">
            {["name", "email", "mobile", "subject"].map((field) => (
              <div key={field} className="relative">
                <input
                  type={field === "email" ? "email" : field === "mobile" ? "tel" : "text"}
                  name={field}
                  value={formData[field]}
                  onChange={handleInputChange}
                  className={`w-full border ${
                    errors[field] ? "border-red-500" : "border-gray-300"
                  } rounded-lg px-4 py-3 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500`}
                  placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
                  required
                />
                {errors[field] && (
                  <p className="text-red-500 text-xs mt-1">{errors[field]}</p>
                )}
              </div>
            ))}

            <div className="relative">
              <textarea
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                className={`w-full border ${
                  errors.message ? "border-red-500" : "border-gray-300"
                } rounded-lg px-4 py-3 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 h-24 resize-none`}
                placeholder="Message"
                required
              />
              {errors.message && (
                <p className="text-red-500 text-xs mt-1">{errors.message}</p>
              )}
            </div>
            <button
          type="submit"
          className="mt-6 w-full bg-gradient-to-r from-purple-500 to-pink-600 text-white py-3 rounded-lg text-lg font-semibold hover:from-purple-600 hover:to-pink-700 transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Sending..." : "Send Message"}
        </button>

          </div>
        )}

      
      </form>
    </div>
  );
};

export default ContactUs;
